<template>
	<div class="container">
		<el-form :model="ruleForm" ref="ruleForm" label-width="320px">
			<el-card class="box-card">
				<div class="content">
					<el-form-item
						label="待付款订单自动取消时间："
						:inline-message="true"
						prop="autoCancelTime"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
					>
						订单拍下后
                        <el-input
							v-model="ruleForm.autoCancelTime"
							style="width:80px;"
                            @input="formatParseInt('autoCancelTime')"
						></el-input>&nbsp; 分钟内未付款，订单自动取消
					</el-form-item>

					<el-form-item
						label="发货后，自动确认收货时间："
						:inline-message="true"
						prop="autoConfirmTime"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
					>
						订单发货后
                        <el-input
							v-model="ruleForm.autoConfirmTime"
							style="width:80px;"
							@input="formatParseInt('autoConfirmTime')"
						></el-input>&nbsp; 天，订单自动确认收货
					</el-form-item>
					<el-form-item
						label="买家寄回退货商品后，商家自动确认收货时间："
						:inline-message="true"
						prop="returnAutoConfirm"
						:rules="[{required:true,message:'至少选择一项',trigger: 'blur'}]"
					>
						<el-form-item>
							<el-radio v-model="ruleForm.returnAutoConfirm" label="1">开启</el-radio>
							<el-radio v-model="ruleForm.returnAutoConfirm" label="2">关闭</el-radio>
						</el-form-item>
						<el-form-item
							prop="returnAutoConfirmTime"
							:inline-message="true"
							v-if="ruleForm.returnAutoConfirm == 1"
							:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						>
							订单发货后
                            <el-input
                                v-model="ruleForm.returnAutoConfirmTime"
                                style="width:80px;"
                                @input="formatParseInt('returnAutoConfirmTime')"
                            ></el-input>&nbsp; 天，订单自动确认收货
						</el-form-item>
					</el-form-item>

					<el-form-item label="自动评价：" :inline-message="true" prop="isEnableEvaluate">
						<el-form-item>
							<el-radio v-model="ruleForm.isEnableEvaluate" label="1">开启</el-radio>
							<el-radio v-model="ruleForm.isEnableEvaluate" label="2">关闭</el-radio>
						</el-form-item>
						<el-form-item
							prop="evaluateTime"
							:inline-message="true"
							v-if="ruleForm.isEnableEvaluate == 1"
							:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						>
							确认收货后，自动评价时间：
                            <el-input
                                v-model="ruleForm.evaluateTime"
                                style="width:80px;"
                                @input="formatParseInt('evaluateTime')"
                            ></el-input>&nbsp; 天，订单自动好评
						</el-form-item>

						<el-form-item
							prop="evaluateContent"
							:inline-message="true"
							v-if="ruleForm.isEnableEvaluate == 1"
							style="margin-top:10px;"
						>
							评价内容：
							<el-input v-model="ruleForm.evaluateContent" type="textarea" style="width: 300px;"></el-input>
						</el-form-item>
					</el-form-item>

					<el-form-item
						prop="serviceOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="普通客服审单时间："
					>
                        <el-input
                            v-model="ruleForm.serviceOverTime"
                            style="width:80px;"
                            @input="formatParseInt('serviceOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="serviceManagerOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="客服超时审核时间："
					>
                        <el-input
                            v-model="ruleForm.serviceManagerOverTime"
                            style="width:80px;"
                            @input="formatParseInt('serviceManagerOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="refundOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="客服退款审核时间："
					>
                        <el-input
                            v-model="ruleForm.refundOverTime"
                            style="width:80px;"
                            @input="formatParseInt('refundOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="confirmRefundOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="商家同意退货自动关闭时间："
					>
                        <el-input
                            v-model="ruleForm.confirmRefundOverTime"
                            style="width:80px;"
                            @input="formatParseInt('confirmRefundOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="confirmDeliveryOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="商家验货无误自动退款时间："
					>
                        <el-input
                            v-model="ruleForm.confirmDeliveryOverTime"
                            style="width:80px;"
                            @input="formatParseInt('confirmDeliveryOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="confirmExchangeOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="商家同意换货自动关闭时间："
					>
                        <el-input
                            v-model="ruleForm.confirmExchangeOverTime"
                            style="width:80px;"
                            @input="formatParseInt('confirmExchangeOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="exchangeDeliveryOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="换货审核通过自动确认收货时间："
					>
                        <el-input
                            v-model="ruleForm.exchangeDeliveryOverTime"
                            style="width:80px;"
                            @input="formatParseInt('exchangeDeliveryOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

					<el-form-item
						prop="buyerDeliverOverTime"
						:inline-message="true"
						:rules="[{required:true,message:'时间不能为空',trigger: 'blur'}]"
						label="买家售后退货自动关闭时间："
					>
                        <el-input
                            v-model="ruleForm.buyerDeliverOverTime"
                            style="width:80px;"
                            @input="formatParseInt('buyerDeliverOverTime')"
                        ></el-input>&nbsp; 天
					</el-form-item>

                    <el-form-item
						prop="buyerAfterSaleCount"
						:inline-message="true"
						:rules="[{required:true,message:'次数不能为空',trigger: 'blur'}]"
						label="用户可发起售后次数："
					>
                        <el-input
                            v-model="ruleForm.buyerAfterSaleCount"
                            style="width:80px;"
                            @input="formatParseInt('buyerAfterSaleCount')"
                        ></el-input>&nbsp; 次
					</el-form-item>

                    <el-form-item
						prop="orderFinishTime"
						:inline-message="true"
						:rules="[{required:true,message:'次数不能为空',trigger: 'blur'}]"
						label="订单自动完成时间："
					>
                        <el-input
                            v-model="ruleForm.orderFinishTime"
                            style="width:80px;"
                            @input="formatParseInt('orderFinishTime')"
                        ></el-input>&nbsp; 天
                        <div style="color:#999999;font-size:12px;">收货后多长时间完成订单，订单完成后不能进行售后</div>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<div style="height:80px;"></div>
		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button style="width:240px;" type="primary" @click="saveSupiler()" :loading="loading">保存</el-button>
		</div>
	</div>
</template>

<script>
import { getTransactionSetting, saveTransactionSetting } from "@/api/setting";
export default {
	data () {
		return {
			ruleForm: {
				autoCancelTime: "",   //待付款订单自动取消时间
                autoConfirmTime: "",  //自动确认收货时间
				returnAutoConfirm: '1',     //退货，商家自动确认收货开关
				returnAutoConfirmTime: "",    //退货，商家自动确认收货时间

				isEnableEvaluate: '1', //自动评价开关
				evaluateTime: '',    //自动评价时间
				evaluateContent: '', //自动评价内容
				serviceOverTime: '', //普通客服审单时间
				serviceManagerOverTime: '',  //客服超时审核时间
				refundOverTime: '',  //客服退款审核时间
				confirmRefundOverTime: '', //商家同意退货自动关闭时间
				confirmDeliveryOverTime: '', //商家验货无误自动退款时间
				confirmExchangeOverTime: '',     //商家同意换货自动关闭时间
				exchangeDeliveryOverTime: '',    //换货审核通过自动确认收货时间
                buyerDeliverOverTime: '',    //买家售后退货自动关闭时间
                buyerAfterSaleCount:'', //用户可发起售后次数
                orderFinishTime:'', //订单自动完成时间
			},
			loading: false,  //保存接口防止多次点击
		};
	},
	computed: {

	},
	created () {
		this.getTransactionSetting();
	},

	methods: {
		//获取详细数据
		async getTransactionSetting () {
			let res = await getTransactionSetting();
			this.ruleForm = res.data;

		},
		// 保存
		saveSupiler () {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		//保存
		async sureSave () {
            this.loading = true
            
			let res = await saveTransactionSetting(this.ruleForm);

			if (res.success) {
				this.$message({
					type: 'success',
					message: '保存成功!'
				});
			}
			this.loading = false;
		},
		//取消保存
		cancelSupiler () {
			this.getTransactionSetting();
        },
        //格式化正整数
        formatParseInt(key){
            var tempNum = this.ruleForm[key];
            tempNum = tempNum.replace(/[^\d]/g, ''); // 清除“数字”和“.”以外的字符
            if (tempNum.indexOf('.') < 0 && tempNum != '') {
                // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
                tempNum = parseInt(tempNum);
            }
            this.ruleForm[key] = tempNum;
        }
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}
</style>
